<template>
  <v-card min-width="800px">
    <v-toolbar color="green" class="ma-0" dark flat> 
      <v-toolbar-title>
        <div style="font-size: 14px; font-weight: 100;">
          <span>Редактирование пользователя</span>
        </div>
        <div>
          "{{ current_user.name }}"
        </div>
      </v-toolbar-title>
      <template v-slot:extension>
        <v-tabs
          v-model="tab"
        >
          <v-tab>Свойства</v-tab>
          <v-tab>Изменить пароль</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <div class="pa-3" style="min-height: 500px">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text class="d-flex flex-column" v-if="!loading">
            <TextField v-model="current_user.id" label="id" class="shrink" style="min-width: 450px;" disabled readonly/>
            <TextField v-model="current_user.email" :rules="[rules.required]" label="Email" style="min-width: 450px" disabled class="shrink" readonly/>
            <TextField v-model="current_company.name" class="shrink" style="min-width: 450px" label="Компания"  disabled readonly/>
            <TextField v-model="current_user.name" :rules="[rules.required]" label="Имя" style="min-width: 450px" class="shrink"/>
            <TextField v-model="current_user.phone" :rules="[rules.phone_validator]" label="Телефон" style="min-width: 450px" class="shrink"/>
          </v-card-text>
          <v-card-text
            v-else
          >
            <div class="d-flex flex-column align-center">
              <Loader />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn :loading="saving" text color="success" dark @click="changeSelf">Сохранить</v-btn>
            <v-spacer />
            <v-btn text color="red" dark @click="onClose">Назад</v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <v-card-text class="d-flex flex-column">
            <TextField type="password" v-model="change_password.old_password" :rules="[rules.required]" label="Старый пароль" style="min-width: 450px" class="shrink"/>
            <TextField type="password" v-model="change_password.new_password" :rules="[rules.required]" label="Новый пароль" style="min-width: 450px" class="shrink"/>
            <TextField type="password" v-model="change_password.confirmation_password" :rules="[rules.required, rules.password]" label="Подтвердить пароль" style="min-width: 450px" class="shrink"/>
            <!-- <span style="color: green;" v-if="change_password.changed">Пароль был успешно изменен.</span>
            <span style="color: red;" v-if="error.state">{{ error.text }}</span> -->
          </v-card-text>
          <v-card-actions>
            <v-btn :loading="saving" text color="success" dark @click="changePassword">Изменить пароль</v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
      <v-snackbar
        v-model="success"
        color="green darken-1"
        dark
        bottom
        :timeout="4000"
      ><v-icon>mdi-check-circle</v-icon> Изменения приняты</v-snackbar>
      <v-snackbar
        v-model="error.state"
        dark
        bottom
        :timeout="4000"
      ><v-icon color="red">mdi-alert-circle</v-icon> {{ error.text }}</v-snackbar>
    </div>
  </v-card>
</template>


<script>
import {mapGetters} from 'vuex'
import TextField from '../../ui/TextField.vue'
import Loader from '../../ui/Loader.vue'

export default {
  name: 'SelfEditor',
  components: {
    TextField, Loader
  },
  data: () => ({
    current_user: {},
    current_company: {},
    saving: false,
    loading: true,
    tab: null,
    error: {
      state: false,
      text: '',
    },
    change_password: {
      changed: false,
      old_password: '',
      new_password: '',
      confirmation_password: ''
    },
    success: false
  }),
  computed: {
    ...mapGetters(['user']),
    rules() {
      return {
        required: value => !!value || 'Поле необходимо заполнить',
        password: value => value == this.change_password.new_password && value == this.change_password.confirmation_password || 'Введен некорректный пароль',
        phone_validator: value => {
          if (!!value == true) {
            if (value.length > 100) {
              return 'Слишком длинный номер телефона'
            }
          }
          return true
        },
      }
    }
  },
  async mounted() {
    this.current_user = { ...this.user }
    this.current_company = await this.$store.dispatch('get_user_company')
    this.loading = false
  },

  methods: {
    async changeSelf() {
      try {
        this.saving = true
        await this.$store.dispatch('user_self_update', this.current_user)
        this.success = true
      } catch(e) {
        this.error.state = true
        this.error.text = e.message
      }
      this.saving = false

    },
    async changePassword() {
      this.saving = true
      let data = {
        user_id: this.current_user.id,
        old_password: this.change_password.old_password,
        new_password: this.change_password.new_password
      } 
      try {
        await this.$store.dispatch('change_password', data)
        this.change_password.changed = true
        setTimeout(() => this.change_password.changed = false, 2000)
        this.error.state = false
      } catch(e) {
        this.error.state = true
        this.error.text = e.message
      }
      this.saving = false
    },

    onClose() {
      this.$emit('onCloseEdit')
    }
  }
}
</script>