<template>
  <v-card min-width="800px">
    <v-toolbar color="green" class="ma-0" dark flat>
      
      <v-toolbar-title>
        <div style="font-size: 14px; font-weight: 100;">
          <span v-if="!isNew">Редактирование компании</span>
          <span v-else style="font-size: 18px; font-weight: 400;">Создание компании</span>
        </div>
        <div v-if="!isNew">
          "{{ current_company.name }}"
        </div>
      </v-toolbar-title>
      <template v-slot:extension>
        <v-tabs
          v-model="tab"
        >
          <v-tab>Свойства</v-tab>
          <v-tab v-if="!isNew">API Токены</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <div class="pa-3" style="min-height: 500px">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text class="d-flex flex-column">
            <TextField v-model="current_company.id" label="id" class="shrink" style="min-width: 450px" disabled readonly/>
            <TextField v-model="current_company.name" :rules="[rules.required]" label="Название" style="min-width: 450px" class="shrink"/>
            <TextField v-model="current_company.tariff" :rules="[rules.required, rules.tariff_validator]" step=".01" type="number" hide-spin-buttons label="Тариф" style="min-width: 450px" class="shrink"/>
            <TextField v-model="current_company.link" :rules="[rules.link_validator]" label="Ссылка на карточку из ПланФикс" placeholder="https://carbis.planfix.ru/contact/ХХХ" style="min-width: 450px" class="shrink"/>
            <v-textarea
              label="Комментарий"
              rows="2"
              :value="current_company.comment"
              v-model="current_company.comment"
            ></v-textarea>
            <TextField v-model="current_company.legal_entity" label="Юридическое лицо"/>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <TokenEditor />
        </v-tab-item>
      </v-tabs-items>
      
    </div>
    
    <v-card-actions>
        <v-btn :loading="saving" text color="success" dark @click="checkTab">Сохранить</v-btn>
        <v-spacer />
        <v-btn text color="red" dark @click="back">Отмена</v-btn>
    </v-card-actions>
    <v-snackbar
      v-model="success"
      color="green darken-1"
      dark
      bottom
      :timeout="4000"
    ><v-icon>mdi-check-circle</v-icon> {{ success_message }}</v-snackbar>
    <v-snackbar
      v-model="error.state"
      dark
      bottom
      :timeout="4000"
    ><v-icon color="red">mdi-alert-circle</v-icon> {{ error.message }}</v-snackbar>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import TextField from '../../ui/TextField.vue'
import TokenEditor from './TokenEditor.vue'

export default {
  data: () => ({
    current_company: {},
    tokens : [],
    token_edit: true,
    tab: null,
    error: {
      state: false,
      message: 'Ошибка!'
    },
    saving: false,
    success: false
  }),
  components: {
    TextField, TokenEditor
  },
  async mounted() {
    if (this.$route.params.company_id) {
      if (!this.isNew) {
        this.current_company = this.company(this.$route.params.company_id)
      }
    }

  },
  computed: {
    ...mapGetters(['company']),

    isNew() {
      return this.$route.params.company_id == 'new'
    },

    rules() {
      return {
        required: value => !!value || 'Поле необходимо заполнить',
        tariff_validator: value => (parseFloat(value) >= 0.3) || 'Тариф должен быть больше 0.3',
        link_validator: value => {
          let re = /^https:\/\/carbis\.planfix\.ru\/contact\/\d+$/
          if (re.test(value)) {
            return true
          }
          return 'Некорректная ссылка'
        }
      }
    },

    success_message() {
      return this.isNew ? 'Компания была успешно создана' : 'Изменения приняты'
    }

  },
  methods: {
    async checkTab() {
      let response = {}
      this.saving = true
      try {
        if (this.isNew) {
          response = await this.$store.dispatch('create_company', this.current_company)
          this.$router.replace('/companies/' + response.id)
        } else {
          response = await this.$store.dispatch('update_company', this.current_company)
          this.current_company = response
        }
      } catch(e) {
          this.error.state = true
          this.error.message = e.message
      }
      this.saving = false
      this.success = true
    },
    back() {
      this.$router.replace('/companies')
    }
  }
}
</script>