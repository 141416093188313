<template>
  <v-app id="inspire">
    <v-app-bar
          app
          clipped-right
          color="green"
          dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Call Password</v-toolbar-title>
        <v-spacer></v-spacer>
        

        <v-btn color="green darken-2" @click="editor = true">
          <v-icon class="mr-2">mdi-account</v-icon>
          <span class="mr-2">Пользователь</span>
          <span>{{ user.name }}</span>
        </v-btn>
        <v-dialog
          v-model="editor"
          max-width="800px"
        >
          <SelfEditor @onCloseEdit="editor=false" />
        </v-dialog>
        <!-- <span>{{ user.is_admin ? 'Администратор' : 'Пользователь' }}</span> -->
        
        <v-progress-linear
            absolute
            bottom
            color="green darken-3"
        />
      </v-app-bar>

      <v-navigation-drawer app v-model="drawer">
        <!-- Панель навигации -->
        <div class="pa-2">
          <v-img src="../../assets/carbis.png" width="80%"/>
        </div>
        <v-list>
          <v-list-item link to="/" exact>Баланс</v-list-item>
          <v-list-item link to="/tokens" exact>Токены</v-list-item>
          <v-list-item link to="/transactions">Транзакции</v-list-item>
          <v-list-item link @click="logout">
            <v-icon class="mr-4">mdi-door</v-icon>
            Выйти
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main style="position: relative;">
        <div class="d-flex flex-column fill-height">
          <!-- <div class="mx-4 py-4" style="background: #fff; position: sticky; top: 60px; z-index: 4;">Bread-crumbs</div> -->
          <div class="pa-2 flex-grow-1">
            <router-view  />
          </div>
        </div>
        
        
      </v-main>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import SelfEditor from '../../components/editors/user/SelfEditor.vue'

  export default {
    name: 'User',
    data: () => ({
      editor: false,
      drawer: true
    }),
    components: {
      SelfEditor
    },
    watch: {
      $route(to) {
        if (to.matched.reduce((prev, cur) => prev || cur.meta?.admin_only, false)) {
          this.$router.replace('/')
        }
       }
    },
    methods: {
      async logout() {
        this.$router.replace('/')
        await this.$store.dispatch('logout')
      },
    },
    computed: {
      ...mapGetters(['user'])
    }
  }
</script>