<template>
  <v-card min-width="800px">
    <v-toolbar color="green" class="ma-0" dark flat>
      <v-toolbar-title>
        <div style="font-size: 14px; font-weight: 100;">
          <span v-if="!isNew">Редактирование пользователя</span>
          <span v-else style="font-size: 20px; font-weight: 500;">Создание пользователя</span>
        </div>
        <div v-if="!isNew">
          "{{ current_user.name }}"
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <div class="pt-3 pr-3 pl-3 pb-0" style="min-height: 500px">
      <v-card-text class="d-flex flex-column">
        <TextField v-if="!isNew" v-model="current_user.id" label="id" class="shrink" style="min-width: 450px" disabled readonly/>
        <v-autocomplete
          v-if="isNew"
          :items="companies.filter(el => !el.deleted)"
          v-model="current_user.company_id"
          item-text="name"
          item-value="id"
          label="Компания"
          :rules="[rules.required]"
        ></v-autocomplete>
        <TextField v-model="current_user.email" :rules="[rules.email_validator]" :disabled="!isNew" label="Email" style="min-width: 450px" class="shrink" :readonly="!isNew"/>
        <TextField v-model="current_user.name" :rules="[rules.required]" label="Имя" style="min-width: 450px" class="shrink"/>
        <div class="d-flex justify-space-between align-center">
          <TextField type="password" v-model="password" :rules="isNew ? [rules.required] : []" label="Пароль" style="min-width: 600px" class="shrink"/>
          <v-btn class="success" type="text" @click="generatePassword" :loading="password_state" small>Сгенерировать</v-btn>
        </div>
        <v-checkbox v-model="send_mail" label="Отправить пароль на почту" class="ma-0" :disabled="check_password"></v-checkbox>
        <TextField v-model="current_user.phone" :rules="[rules.phone_validator]" label="Телефон" style="min-width: 450px" class="shrink"/>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="saving" text color="success" dark @click="checkTab">{{ isNew ? 'Создать' : 'Сохранить' }}</v-btn>
        <v-spacer />
        <v-btn text color="red" dark @click="back">Назад</v-btn> 
      </v-card-actions>
    </div>
    <v-snackbar
      v-model="success"
      color="green darken-1"
      dark
      bottom
      :timeout="4000"
    ><v-icon>mdi-check-circle</v-icon> {{ success_message }}</v-snackbar>
    <v-snackbar
      v-model="error.state"
      dark
      bottom
      :timeout="4000"
    ><v-icon color="red">mdi-alert-circle</v-icon> {{ error.message }}</v-snackbar>
  </v-card>
</template>

<script>

import {mapGetters} from 'vuex'
import TextField from '../../ui/TextField.vue'

export default {
  name: 'UserEditor',
  components: {
    TextField
  },
  data: () => ({
    current_user: {},
    saving: false,
    send_mail: false,
    // tab: null,
    password: undefined,
    password_state: false,
    change_password: {
      old_password: '',
      new_password: '',
      confirmation_password: ''
    },
    error: {
      state: false,
      message: ''
    },
    success: false
  }),
  computed: {
    ...mapGetters(['get_user', 'companies', 'user']),
    isNew() {
      return this.$route.params.user_id == 'new'
    },
    rules() {
      return {
        required: value => !!value || 'Поле необходимо заполнить',
        password: value => value == this.change_password.new_password && value == this.change_password.confirmation_password || 'Введен некорректный пароль',
        email_validator: value => {
          let re = /\S+@\S+\.\S+/
          return (re.test(value)) ? true : 'Некорректный адрес почты';
        },
        phone_validator: value => {
          if (!!value == true) {
            if (value.length > 100) {
              return 'Слишком длинный номер телефона'
            }
          }
          return true
        },
      }
    },
    check_password() {
      if(this.isNew) {
        return false
      }
      return (!this.password)
    },
    success_message() {
      return this.isNew ? 'Пользователь был успешно создан' : 'Изменения приняты'
    },
    request_user() {
      let request = this.current_user
      request.password = this.password
      request.send_mail = this.send_mail
      return request
    }
  },
  async mounted() {
    if (this.$route.params.user_id) {
      if (!this.isNew) {
        this.current_user = this.get_user(this.$route.params.user_id)
      } else {
        this.send_mail = true
      }
    }
  },
  methods: {
    async checkTab() {
      let response = {}
      this.saving = true
      try {
        if (this.isNew) {
          response = await this.$store.dispatch('create_user', this.request_user)
          this.$router.replace('/users/' + response.id)
        } else {
          response = await this.$store.dispatch('update_user', this.request_user)
          this.current_user = response
        }
      } catch(e) {
        this.error.state = true
        this.error.message = e.message
      }
      this.success = true
      this.saving = false
    },
    async changePassword() {
      let data = {
        user_id: this.current_user.id,
        old_password: this.change_password.old_password,
        new_password: this.change_password.new_password
      } 
      await this.$store.dispatch('change_password', data)
    },

    async generatePassword() {
      this.password_state = true
      try {
        let new_pass = await this.$store.dispatch('generate_password')
        this.password = new_pass
        
      } catch(e) {
        this.error.state = true
        this.error.message = 'Не удалось сгенерировать пароль: ' + e.message
      }
      this.password_state = false
    },

    back() {
      this.$router.replace('/users')
    }

  }

}
</script>