<template>
  <component :is="editor" />
</template>

<script>

import CompanyEditor from '../components/editors/company/CompanyEditor.vue'
import UserEditor from '../components/editors/user/UserEditor.vue'

export default {
  components: {
    CompanyEditor, UserEditor
  },
  mounted() {
  },
  computed: {
    editor() {
      return this.$route.name
    }
  }
}
</script>