import Vue from 'vue'
import VueRouter from 'vue-router'

import ModalContainer from '../views/ModalContainer.vue'
import EditorProxy from '../views/EditorProxy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('../views/Main.vue'),
  },
  {
    path: '/companies',
    component: () => import('../views/Companies.vue'),
    meta: {
      admin_only: true,
    },
    children : [
      {
        path : ':company_id',
        component: ModalContainer,
        children: [
          {
            name: 'CompanyEditor',
            path: '',
            component: EditorProxy
          }
        ]
      }
    ]
  },
  {
    path: '/users',
    component: () => import('../views/Users.vue'),
    children: [
      {
        path: ':user_id',
        component: ModalContainer,
        children: [
          {
            name: 'UserEditor',
            path: '',
            component: EditorProxy
          }
        ]
      }
    ]
  },
  {
    path: '/operations',
    component: () => import('../views/Operations.vue'),
    children: [
      {
        path: ':operation_id',
        component: ModalContainer,
        children: [
          {
            name: 'OperationEditor',
            path: '',
            component: EditorProxy
          }
        ]
      }
    ]
  },
  {
    path: '/reports',
    component: () => import('../views/Reports.vue')
  },
  {
    path: '/balance',
    component: () => import('../views/Balance.vue')
  },
  {
    path: '/transactions',
    component: () => import('../views/Transactions.vue')
  },
  {
    path: '/tokens',
    component: () => import('../views/Tokens.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
