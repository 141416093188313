export default {
  actions: {
    async download_operations_report(ctx, data) {
      await ctx.dispatch('api_request_file', {
        endpoint: '/report/operations/create_report',
        body: data,
        result_name: 'operations_report_' + data.date_from + '_' + data.date_to  + '.csv'
      })
    },
    async download_history_report(ctx, data) {
      await ctx.dispatch('api_request_file', {
        endpoint: '/report/history/create_report',
        body: data,
        result_name: 'history_report_' + data.date_from + '_' + data.date_to  + '.csv'
      })
    },
    async get_report_operations(ctx, data) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/report/operations',
        body: data
      })
      // console.log('Operations', response)
      return response
    },
    async get_report_history(ctx, data) {
      return await ctx.dispatch('api_request', {
        endpoint: '/report/history',
        body: data
      })
    }
  }
}