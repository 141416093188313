import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from './modules/user'
import api from './modules/api'
import companies from './modules/companies'
import tokens from './modules/tokens'
import authorization from './modules/authorization'
import users from './modules/users'
import operations from './modules/operations'
import reports from './modules/reports'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user, 
    api,
    companies,
    authorization,
    tokens,
    users,
    operations,
    reports
  }
})
