<template>
  <v-dialog v-model="visible" scrollable max-width="min-content">
    <router-view />
  </v-dialog>
</template>


<script>
export default {
  data: () => ({
    visible: true,
  }),
  computed:{
    
  },
  watch: {
    visible(value) {
      if (value == false) {
        this.$router.push(this.$route.matched[this.$route.matched.length - 3])
      }
    }
  }
}
</script>