<template>
  <div id="app">
    <component :is="layout" />
  </div>
  <!-- <v-app>
    <v-main style="background-color: rgb(229, 241, 229);">
      <component :is="layout" />
    </v-main>
  </v-app> -->
</template> 

<script>

import Admin from './views/layouts/Admin'
import Login from './views/layouts/Login'
import User from './views/layouts/User'
import UserLoading from './views/layouts/UserLoading'

import {mapGetters} from 'vuex'

export default {
  name: 'App',
  components: {
    Admin, User, Login, UserLoading
  },
  data: () => ({
    //
    user_loading : true,
  }),
  computed: {
    ...mapGetters(['token', 'user']), 

    layout() {
      if (this.user_loading) {
        return 'UserLoading'
      } else if (this.token == undefined) {
        return 'Login'
      } else {
        if (this.user.is_admin) {
          return 'Admin'
        } else {
          return 'User'
        }
      }
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('find_token')
    }
    catch(e) {
      console.error(e.message)
    }
    this.user_loading = false
  }
};
</script>
