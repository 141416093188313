<template>
  <div>
    <RefNavigator 
      :items="tokens" 
      :headers="headers" 
      :hasAdditionBtn="true" 
      :hasSearchTextField="true" 
      :hasShowRemovedBtn="true" 
      :hasEditBtns="true"
      @click:add="dialog=true"
      @delete="onDelete"
      @restore="onRestore"
    />
    <v-dialog
      v-model="dialog"
      max-width="min-content"
    >
      <v-card min-width="300px">
        <v-toolbar color="green" class="ma-0" dark flat>
          <v-toolbar-title>
          <span>Создание токена</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <TextField v-model="new_token_comment" label="Комментарий к новому токену" />
        </v-card-text>

        <v-card-actions>
            <v-btn text color="success" dark @click="saveToken">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import TextField from '../../ui/TextField.vue'
import RefNavigator from '../../RefNavigator.vue'

export default {
  components: {
    TextField, RefNavigator
  },
  data: () => ({
    tokens : [],
    dialog: false,
    new_token_comment : '',
    current_token: undefined
  }),
  async mounted() {
    this.tokens = await this.$store.dispatch('load_tokens', this.$route.params.company_id)
  },
  computed: {
    headers() {
      return [
        {
          text: 'Значение',
          align: 'start',
          value: 'value'
        },
        {
          text: 'Комментарий',
          value: 'comment'
        }
      ]
    }
  },
  methods: {
    async saveToken() {
      let response = await this.$store.dispatch('create_token', {
        company_id: this.$route.params.company_id, 
        token: { comment : this.new_token_comment }
      })
      this.tokens.push(response)
      this.new_token_comment = ''
      this.dialog = false
    },
    update_tokens(token) {
      for (let obj in this.tokens) {
        if (obj.id == token.id) {
          obj = token
        }
      }
    },
    async onDelete(token) {
      this.current_token = token
      this.current_token.deleted = true
      this.current_token = await this.$store.dispatch('update_token', this.current_token)
      this.update_tokens(this.current_token)
      this.current_token = undefined
    },
    async onRestore(token) {
      this.current_token = token
      this.current_token.deleted = false
      this.current_token = await this.$store.dispatch('update_token', this.current_token)
      this.update_tokens(this.current_token)
      this.current_token = undefined
    }
  }
}
</script>