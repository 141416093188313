<template>

  <div class="pa-3">
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-btn class="mr-6" color="success" v-if="hasAdditionBtn" @click="$emit('click:add')">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Добавить
        </v-btn>        
        <v-switch label="Показать удаленные" color="green" v-if="hasShowRemovedBtn" v-model="show_deleted"/>
      </div>
      <div>
        <v-text-field 
          v-model="search" 
          label="Поиск" 
          color="green" 
          class="justify-end" 
          append-icon="mdi-magnify" 
          single-line
          v-if="hasSearchTextField"
        />
      </div>
    </div>
    <div>
       <v-data-table
        :headers="modiHeaders"
        :items="filteredItems"
        item-key="id"
        :items-per-page="100"
        :search="search"
        @click:row="e => $emit('click:row', e)"
        :item-class="(item) => item.deleted ? 'deleted' : ''"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

      >
      <!-- @click:row="e => $emit('click:row', e)" -->
        <template v-slot:item.actions="{ item }" v-if="hasEditBtns">
          <v-icon
            @click.stop="show('delete', item)"

            v-if="!item.deleted"
          >
            mdi-delete
          </v-icon>
          <v-icon
            @click.stop="show('restore', item)"
            v-else
          >
            mdi-backup-restore
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog
        v-model="delete_dialog"
        width="400px"
      >
        <v-card>
          <v-card-title>
            Подтверждение удаления данных
          </v-card-title>
          <v-card-text>
            Вы уверены, что хотите удалить запись?
          </v-card-text>
          <v-card-actions>
            <v-btn 
              width="150px" 
              color="success" 
              outlined
              text
              @click="deleteItem"
            >
              Да
            </v-btn>
            <v-spacer/>
            <v-btn @click="delete_dialog = false" text width="150px" color="red" dark outlined>
              Нет
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="restore_dialog"
        width="400px"
      >
        <v-card>
          <v-card-title>
            Подтверждение восстановления данных
          </v-card-title>
          <v-card-text>
            Вы уверены, что хотите восстановить запись?
          </v-card-text>
          <v-card-actions>
            <v-btn 
              width="150px" 
              color="success" 
              text
              outlined
              @click="restoreItem"
            >
              Да
            </v-btn>
            <v-spacer/>
            <v-btn @click="restore_dialog = false" text width="150px" color="red" dark outlined>
              Нет
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>

</template>

<style>
.deleted {
  background: rgb(255, 189, 189);
}
</style>

<script>


export default {
  name: 'RefNavigator',
  props: ['headers', 'items', 'hasAdditionBtn', 'hasShowRemovedBtn', 'hasSearchTextField', 'hasEditBtns'],
  components: {
  },
  data: () => ({
    search: '',
    show_deleted: false,
    delete_dialog: false,
    restore_dialog: false,
    cur_item : undefined,
    sortBy: 'id',
    sortDesc: true

  }),
  mounted() {
  },
  methods: {
    deleteItem() {
      this.cur_item.deleted = true
      this.$emit('delete', this.cur_item)
      this.cur_item = undefined
      this.delete_dialog = false
    },
    restoreItem() {
      this.cur_item.deleted = false
      this.$emit('restore', this.cur_item)
      this.cur_item = undefined
      this.restore_dialog = false
    },
    show(action, item) {
      this.cur_item = item
      if (action == 'delete') {
        this.delete_dialog = true
      } else if (action == 'restore') {
        this.restore_dialog = true
      }
    }
  },
  computed: {
    filteredItems() {
      if (this.hasShowRemovedBtn) {
        if (!this.show_deleted) {
          return this.items.filter(item => !item.deleted)
        } else {
          return this.items
        }
      }
      return this.items
    },
    modiHeaders() {
      return this.hasEditBtns ? [ ...this.headers, {
        text: 'Действия',
        value: 'actions'
      } ] : this.headers
    }
  },

  

}
</script>