export default {
  actions: {
    async load_tokens(ctx, company_id) {
      return await ctx.dispatch('api_request', {
        endpoint: '/company/' + company_id + '/tokens'
      })
    },
    async create_token(ctx, { company_id, token }) {
      return await ctx.dispatch('api_request', {
        endpoint: '/company/' + company_id + '/tokens/new',
        body: token
      })
    },
    async update_token(ctx, data) {
      return await ctx.dispatch('api_request', {
        endpoint: '/token/update',
        body: data
      })
    },
  }
}