export default {
  state: {
    users: [],
  },
  getters: {
    users(state) {
      return state.users
    },
    get_user(state) {
      return (id) => {
        return { ...state.users.filter(elem => elem.id == id)[0] }
      }
    }
  },
  mutations: {
    set_users(state, users) {
      state.users = users
    },
    append_user(state, new_user) {
      state.users.push(new_user)
    },
    update_user(state, updated_user) {
      state.users = state.users.map((elem) => {
        if (elem.id == updated_user.id) {
          return updated_user
        }
        return elem
      })
    }
  },
  actions: {
    async load_users(ctx) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/users/all'
      })
      ctx.commit('set_users', response)
    },

    async create_user(ctx, user) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/users/new',
        body: user
      })
      ctx.commit('append_user', response)
      return response
    },

    async update_user(ctx, user) {
      if (!!user.password == true) {
        let body = {
          user_id: user.id,
          new_password: user.password,
          send_mail: user.send_mail
        }
        await ctx.dispatch('change_password', body)
      }
      let response = await ctx.dispatch('api_request', {
        endpoint: '/user/update',
        body: user
      })
      ctx.commit('update_user', response)
      return response
    },
    async delete_user(ctx, data) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/user/delete',
        body: data
      })
      ctx.commit('update_user', response)
      return response
    },
    async restore_user(ctx, data) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/user/restore',
        body: data
      })
      ctx.commit('update_user', response)
      return response
    },

    async change_password(ctx, data) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/user/change_password',
        body: data
      })

      return response
    },
    async generate_password(ctx) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/user/generate_password',
      })
      return response.password
    }

  },
}