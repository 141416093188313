export default {
  actions: {
    async send_operation(ctx, {company_id, operation}) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/company/' + company_id + '/operations/new',
        body: operation
      })
      return response
    },
    async get_company_operations(ctx, company_id){
      let response = await ctx.dispatch('api_request', {
        endpoint: '/company/' + company_id + '/operations'
      })
      return response
    },
    async get_all_operations(ctx) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/operations/all'
      })
      return response
    }
  }
}