export default {
  state: {
    companies : [],
  },
  getters : {
    companies(state) {
      return state.companies
    },
    company(state) {
      return (id) => {
        return { ...state.companies.filter(elem => elem.id == id)[0] }
      }
    }
  },
  mutations: {
    set_companies(state, data) {
      state.companies = data
    },
    append_company(state, new_company) {
      state.companies.push(new_company)
    },
    update_company(state, updated_company) {
      state.companies = state.companies.map((elem) => {
        if (elem.id == updated_company.id) {
          return updated_company
        }
        return elem
      })

    }
  },
  actions: {
    async load_companies(ctx) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/company/all'
      })

      ctx.commit('set_companies', response)
    },

    async create_company(ctx, data) {
      let response = await ctx.dispatch('api_request',{
        endpoint: '/company/new',
        body: data
      })
      ctx.commit('append_company', response)
      return response
    },

    async update_company(ctx, data) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/company/update',
        body: data
      })
      ctx.commit('update_company', response)
      return response
    },

    async delete_company(ctx, data) {
        let response = await ctx.dispatch('api_request', {
        endpoint: '/company/delete',
        body: data
      })
      ctx.commit('update_company', response)
    },
    
    async restore_company(ctx, data) {
      data.deleted = false
      let response = await ctx.dispatch('api_request', {
        endpoint: '/company/restore',
        body: data
      })
      ctx.commit('update_company', response)
    },

    async ping_company(ctx, company_id) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/ping_company',
        body: {
          id: company_id
        }
      })
      ctx.commit('update_company', response)
      return response
    }
  }
}