<template>
  <v-app id="inspire">
    <v-app-bar
          app
          clipped-right
          color="green"
          dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Call Password</v-toolbar-title>
        <v-spacer></v-spacer>
        
        <v-btn color="green darken-2">
          <v-icon class="mr-2">mdi-account</v-icon>
          <span class="mr-2">Администратор</span>
          <span>{{ user.name }}</span>
        </v-btn>
        <!-- <span>{{ user.is_admin ? 'Администратор' : 'Пользователь' }}</span> -->
        
        <v-progress-linear
            absolute
            bottom
            color="green darken-3"
        />
      </v-app-bar>

      <v-navigation-drawer app v-model="drawer">
        <!-- Панель навигации -->
        <div class="pa-2">
          <v-img src="../../assets/carbis.png" width="80%"/>
        </div>
        <v-list>
          <v-list-item link to="/companies">Компании</v-list-item>
          <v-list-item link to="/users">Пользователи</v-list-item>
          <v-list-item link to="/reports">Отчеты</v-list-item>
          <v-list-item link to="/operations">Операции</v-list-item>
          <v-list-item link @click="logout">
            <v-icon class="mr-4">mdi-door</v-icon>
            Выйти
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main style="position: relative;">
        <div class="d-flex flex-column fill-height">
          <!-- <div class="mx-4 py-4" style="background: #fff; position: sticky; top: 60px; z-index: 4;">
            <v-breadcrumbs
              :items="getCrumbs"
              large
            ></v-breadcrumbs>
          </div> -->
          <div class="pa-2 flex-grow-1">
            <router-view  />
          </div>
        </div>

      </v-main>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'Admin',
    data: () => ({
      drawer: true
    }),
    methods: {
      async logout() {
        this.$router.replace('/')
        await this.$store.dispatch('logout')
      }
    },
    computed: {
      ...mapGetters(['user']),
      // getCrumbs() {
      //   let crumbs = this.$route.path
      //   crumbs = crumbs.split('/')
      //   let result = []
      //   for (let i = 0; i < crumbs.length; i++) {
      //     if (i == 0) {
      //       result.push({
      //         text: 'Main',
      //         disabled: false,
      //         href: ''
      //       })
      //     } else {
      //       result.push({
      //         text: crumbs[i][0].toUpperCase() + crumbs[i].slice(1),
      //         disabled: i == crumbs.length-1 ? true : false,
      //         href: '/' + crumbs[i]
      //       })
      //     }
      //   }
      //   console.log(result)
      //   return result
      // }
    }
  }
</script>